import { useContext, useMemo } from 'react';

import { AuthContext } from 'context/AuthContext';

export default function useNexusConfig() {
  const { authToken, masterData, storeProperties } = useContext(AuthContext);

  const axiosConfig = useMemo(() => {
    return {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Token ${authToken}`
      }
    };
  }, [authToken]);

  return {
    masterData,
    storeProperties,
    axiosConfig
  };
}
