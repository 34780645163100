import React, { useContext, useRef, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NexusProvider } from '@return-rabbit/nexus';
import { Helmet } from 'react-helmet';
import _get from 'lodash/get';

import { logError } from 'utils';
import { ErrorBoundary } from 'components';
import Layout from './Layout';
import Logout from './views/Logout';
import { ErrorPage } from 'views/Errors';
import Retailer404 from 'Layout/Retailer404';
import QrShippingLabel from './views/QrShippingLabel';
import ShipmentTrackingApp from 'views/ShipmentTrackingApp';
import { AuthContext } from 'context/AuthContext';
import { useNexusConfig } from 'utils/hooks';
import rrFaviconFallback from 'images/rr-favicon.svg';
import trFaviconFallback from 'images/tr-favicon.svg';

export default function App() {
  const { authToken, appType, getStoreDetails } = useContext(AuthContext);

  const { axiosConfig, masterData, storeProperties } = useNexusConfig();

  const fallbackFavicon = useRef(
    appType.shipmentTracking ? trFaviconFallback : rrFaviconFallback
  ).current;

  const { storeName, faviconLink } = useMemo(() => {
    const storeInfo = getStoreDetails();
    const storeName = _get(storeInfo, 'name', '');
    const faviconLink = storeInfo.favicon || fallbackFavicon;
    return { storeName, faviconLink };
  }, [getStoreDetails]);

  return (
    <>
      <Helmet>
        <title>{storeName ? `${storeName} Support` : 'Returns Portal'}</title>
        <link rel="shortcut icon" href={faviconLink} />
      </Helmet>
      <Retailer404>
        <ErrorBoundary>
          {appType.retex && (
            <NexusProvider
              key={authToken}
              mode="SHOPPER"
              axiosConfig={axiosConfig}
              masterData={masterData}
              storeProperties={storeProperties}
              logError={logError}
            >
              <Switch>
                <Route path="/logout" component={Logout} />
                <Route path="/error" component={ErrorPage} />
                <Route exact path="/qr-shipping-label">
                  <QrShippingLabel storeName={storeName} />
                </Route>
                <Route path="/" component={Layout} />
              </Switch>
            </NexusProvider>
          )}
          {appType.shipmentTracking && (
            <Switch>
              <Route path="/" component={ShipmentTrackingApp} />
            </Switch>
          )}
        </ErrorBoundary>
      </Retailer404>
    </>
  );
}
