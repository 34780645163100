import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';

import PhoneNumber from './PhoneNumber';
import SuggestionText from './SuggestionText';
import OrderNameHelper from './OrderNameHelper';
import { INPUT_ID } from '../../../stubs';
import { AuthContext } from 'context/AuthContext';

export default function Input({
  data = {},
  disabled = false,
  onToggle = () => {},
  toggleDisabled = false
}) {
  const value = _get(data, 'value', '');
  const error = _get(data, 'error', '');
  const id = _get(data, 'htmlInfo.id', '');
  const type = _get(data, 'htmlInfo.type', '');
  const label = _get(data, 'htmlInfo.label', '');
  const placeholder = _get(data, 'htmlInfo.placeholder', '');
  const suggestionData = _get(data, 'htmlInfo.suggestionData', {});

  let toggleTo = useMemo(() => {
    return _get(data, 'htmlInfo.toggleInfo.to', '');
  }, [data]);

  let toggleText = useMemo(() => {
    return _get(data, 'htmlInfo.toggleInfo.text', '');
  }, [data]);

  const onBlur = _get(data, 'onBlur', () => {});
  const onChange = _get(data, 'onChange', () => {});

  const { allowPhoneLogin } = useContext(AuthContext);

  const isPhone = id === INPUT_ID.PHONE && allowPhoneLogin;
  const isOrderNumber = id === INPUT_ID.ORDER;

  if (toggleTo === INPUT_ID.PHONE && !allowPhoneLogin) {
    toggleTo = '';
    toggleText = '';
  }

  const toggleCheck = toggleTo && toggleText && !toggleDisabled;

  return (
    <div className="LoginForm__inputWrapper">
      <label htmlFor={id} className="LoginForm__label">
        {label}
      </label>
      <div className="LoginForm__inputGroup">
        {isPhone ? (
          <PhoneNumber
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            className={cn('LoginForm__phoneInput', { 'is-invalid': !!error })}
          />
        ) : (
          <input
            id={id}
            type={type}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={e => onChange(e.target.value)}
            className={cn('LoginForm__input', { 'is-invalid': !!error })}
          />
        )}
        {isOrderNumber && <OrderNameHelper />}
      </div>
      <SuggestionText value={value} {...suggestionData} />
      {error && (
        <div
          className={`LoginForm__errorMsg ${!toggleCheck ? 'default-pos' : ''}`}
        >
          {error}
        </div>
      )}
      {toggleCheck && (
        <button
          onClick={() => onToggle(toggleTo)}
          className="LoginForm__switchView"
          type="button"
        >
          {toggleText}
        </button>
      )}
    </div>
  );
}
