import React from 'react';
import { logError } from 'utils/extra';

import { connectError } from 'utils/connectors/AppConnectors';
import { ErrorPage, NotFound } from 'views/Errors';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: this.props.hasError };
  }
  componentDidUpdate(prevProps) {
    // Dependent on AuthContext
    if (prevProps.hasError !== this.props.hasError) {
      this.setState({
        hasError: this.props.hasError
      });
    }
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    if (this.state.hasError) return;
    this.setState({ hasError: true });
    // Log to external reporting service
    logError(error, { componentStack: info.componentStack });
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    const { errorObject } = this.props;
    let message = null;
    if (typeof errorObject === 'string') {
      message = errorObject;
    } else if (typeof errorObject === 'object' && errorObject !== null) {
      message =
        typeof errorObject.displayMessage === 'string'
          ? errorObject.displayMessage
          : 'Something went wrong. Please try again later.';
    }
    if (
      typeof errorObject === 'object' &&
      errorObject !== null &&
      errorObject.statusCode === 404 &&
      errorObject.method === 'get'
    ) {
      return <NotFound />;
    }
    return <ErrorPage message={message} />;
  }
}

export default connectError(ErrorBoundary);
