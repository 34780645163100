import { get, logError } from 'utils';
import { formatAndStoreMasterData } from 'utils/methods';

export default function getMasterData(
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  return get('/retailer/masterData/', {}, { noToken: true })
    .then(({ data }) => {
      if (!data) {
        throw new Error('Failed to fetch the master data');
      }
      const { masterData, formattedMasterData } = formatAndStoreMasterData(
        data
      );
      if (!formattedMasterData) {
        throw new Error('Failed to format the masterData');
      }
      onSuccess(masterData, formattedMasterData);
    })
    .catch(err => {
      logError(err || 'Error in loading master data');
      onError(err);
    })
    .finally(onFinally);
}
